import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'

import FreeTrialButton from '../components/free-trial-button';

import savingsSrc from '../images/savings.svg';
import supportSrc from '../images/support.svg';
import warningSrc from '../images/warning.svg';
import supportSmallSrc from '../images/support-small.svg';
import switchToTkSrc from '../images/switch-to-tk.svg';
import clockInSrc from '../images/clockin.png';

import {KGlackinTestimonial} from "../components/testimonial";


const CausewayDonseedAlternative = () => (
  <Layout>
    <SEO
    	title="Causeway Donseed Timesheet Alternative UK | Switch Today for your 3 month trial"
    	description="There are modern solutions to Causeway Donseed that can be more flexible and cost effective"
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			           	Make the switch from <br /> <span className="text-highlight-600"> Causeway Donseed</span>
			          </h2>

			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            Looking for an alternative to Causeway Donseed’s time and attendance system? Get 3 months FREE access to TimeKeeper, plus 50% off your next 3 months, when you switch your account to us.
			          </p>

			          <FreeTrialButton />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={switchToTkSrc} alt="Causeway Donseed vs TimeKeeper" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				      <RightFeature
				    	sectionTitle="Transparent upfront pricing"
				    	title="We cost £3.50 per employee per month"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          You’ll pay for each employee you add to TimeKeeper, plus any optional add-on features you choose, and that’s all - no hidden fees, no set-up costs, and no cumbersome contracts.
						        </FeaturePararaph>
						        <FeaturePararaph>
						           TimeKeeper runs on your everyday devices, making your set up quick, easy and low cost. There’s no professional installation required, so you can start clocking in straight away.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={savingsSrc}
				    	imgAlt="Our pricing is £3.50 per employee per month"
				     />
				     <LeftFeature
				    	sectionTitle="Friendly terms that work for your business"
				    	title="No contract tie ins"
				    	body={
				    		<div>
						        <FeaturePararaph>
						          Our customers stick with us because they love our service, not because of multi-year contracts. Monthly billing gives you the flexibility to add or remove employees as your business grows and evolves, so you have the freedom to scale up or down.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	If you’re not happy, you can cancel any time - without paying a penalty or sending us a signed letter!
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={warningSrc}
				    	imgAlt="Donseed Causeway do multi-year contracts"
				    />

				     <RightFeature
				    	sectionTitle="Runs on your everyday devices"
				    	title="Advanced biometrics on your existing devices"
				    	body={
				    		<div>
						        <FeaturePararaph>
						          Each clock in is secured with a photo and facial recognition, ensuring your business is protected against buddy clock-ins.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	Plus, GPS stamping and geofencing ensures your team is always where they need to be. Automatically block any clock in attempts that occur outside your site boundaries.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={clockInSrc}
				    	imgAlt="No need for specialised hardware, run on everyday devices with TimeKeeper"
				    />
				    <LeftFeature
				    	sectionTitle="We will help you switch"
				    	title="Real human support"
				    	body={
				    		<div>
						        <FeaturePararaph>
						          Our Customer Success team is here to make your transition a breeze; we’re always available during UK working hours via live chat to answer your questions and help you get set up.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={supportSrc}
				    	imgAlt="UK Friendly Support Ready to help"
				    />
				</div>
				<KGlackinTestimonial />
				<FreeTrial
					part1={(<span>Make the switch from  <br/><span className="text-highlight-600">Causeway Donseed</span> today!</span>)}
					part2={(<span>Start your 3-month free trial!</span>)}
				/>
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default CausewayDonseedAlternative;
